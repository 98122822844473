import React, { useState } from 'react';
import useStyles from './styles';
import { Avatar, Box, Typography } from '@material-ui/core';
import SupportContactInfo from 'components/SupportContactInfo/SupportContactInfo';
import TippyAppLogo from '../../../images/TippyAppLogo.svg';
import Button from 'components/Button/Button';
import SupportText from 'components/SupportText/SupportText';
import useIsBlurred from 'hooks/useIsBlurred';

enum EStep {
  NAVIGATE = 'navigate',
  LOADING = 'loading',
  CLOSE = 'close',
}

interface IOpenTippyAppPageProps {
  deepLink: string;
  error: boolean;
}

const ERROR_MESSAGE = 'It looks like you do not have Tippy App on this device.';

const OpenTippyAppPage = (props: IOpenTippyAppPageProps) => {
  const [error, setError] = useState(props.error ? ERROR_MESSAGE : '');
  const [step, setStep] = useState(EStep.NAVIGATE);
  const { wrapper, title, footer, avatar, button } = useStyles();
  const { deepLink } = props;

  const onBlur = () => {
    setError('');
    setStep(EStep.NAVIGATE);
  };

  useIsBlurred(onBlur, null);

  const handleClick = () => {
    setStep(EStep.LOADING);
    window.location.assign(deepLink);
    setTimeout(() => {
      setStep(EStep.CLOSE);
      setError(ERROR_MESSAGE);
    }, 5000);
  };

  const loading = step === EStep.LOADING;

  return (
    <Box className={wrapper}>
      <Avatar className={avatar} variant="rounded" src={TippyAppLogo} />

      <Typography className={title}>Continue to Tippy App</Typography>

      {error && (
        <Box maxWidth={492}>
          <SupportText label={error} />
        </Box>
      )}

      <Typography variant="body2" align="center" style={{ maxWidth: 492 }}>
        It looks like you started something in the Tippy App on your mobile device. For security and
        a seamless experience, please switch back to the Tippy App to complete this process.
      </Typography>

      <Button
        fullWidth
        className={button}
        onClick={handleClick}
        loading={loading}
        disabled={!!error}>
        Open Tippy App
      </Button>

      <Box className={footer}>
        <SupportContactInfo />
      </Box>
    </Box>
  );
};

export default OpenTippyAppPage;
