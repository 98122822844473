import React from 'react';
import { action, IReactionDisposer, observable, reaction, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Box } from '@material-ui/core';

import Api, { getErrorMsg } from 'api';
import { inject, WithToastStore, WithUserStore } from 'stores';
import * as models from 'models';

import AutocompleteSearch from 'components/AutocompleteSearch';

/** Defines any additional search parameters */
interface MetaSearchParams {
  createdAfter?: string; // return only locations that were created after this date
}

interface LocationSearchProps extends WithToastStore, WithUserStore {
  onChange: (a: models.Location | null) => void;
  label?: string;
  value?: models.Location | null;
  hideIcon?: boolean;
  placeholder?: string;
  accountId?: number;
  autoFocus?: boolean;
  metaSearchParams?: MetaSearchParams;
  onlyUnconverted?: boolean;
  dataCy?: string;
}

let abortController = new AbortController();

/** A component that displays a field for searching for locations */
@inject('toastStore', 'userStore')
@observer
class LocationSearch extends React.Component<LocationSearchProps> {
  @observable isAdmin: boolean = this.props.userStore!.isAdmin;

  constructor(props: LocationSearchProps) {
    super(props);
    makeObservable(this);
    // Set up an autorun so that when the user in the props changes, we copy it
    // to this component's state. This achieves the same thing as implementing a
    // componentDidUpdate and seeing if the user prop has changed.
    // We push the autorun onto a disposers array so that we can dispose of all
    // of them before the component unmounts.
    this.disposers.push(
      reaction(
        () => this.props.accountId,
        (accountId) => {
          this.accountId = accountId;
        },
      ),
    );
  }

  private disposers: IReactionDisposer[] = [];

  @observable private accountId = this.props.accountId;

  /** Gets the label for an location in the autocomplete search */
  private getLocationOptionLabel(loc?: models.Location): string {
    if (!loc) {
      // Sometimes we get undefined here, not sure why
      return '';
    } else {
      return loc.name || '';
    }
  }

  /** Renders the option for locations */
  @action.bound private renderLocationOption(acc: models.Location): React.ReactNode {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
        <Box>{this.isAdmin ? acc.locationName : acc.name}</Box>
        <Box>{acc.code}</Box>
      </Box>
    );
  }

  /** Fetch the locations by search string and meta search parameters */
  @action.bound public async searchAllLocations(search: string): Promise<models.Location[]> {
    try {
      const searchParams = { name: search, ...this.props.metaSearchParams };
      const resp = await Api.core.searchAllLocations({}, searchParams, abortController);
      return resp.data ? resp.data.data : [];
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }

  /** Fetch the account locations by search string */
  @action.bound public async searchAccountLocations(search: string): Promise<models.Location[]> {
    try {
      const resp = await Api.core.searchAccountLocations(this.accountId!, { name: search });
      return resp.data ? resp.data.data : [];
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }

  /**
   * Fetch the locations without associated affiliate
   * that are not older than 30 days by search string
   */
  @action.bound public async searchUnconvertedLocations(
    search: string,
  ): Promise<models.Location[]> {
    try {
      const resp = await Api.core.searchUnconvertedLocations({ name: search });
      return resp.data ? resp.data.data : [];
    } catch (e: any) {
      this.props.toastStore!.push({ type: 'error', message: getErrorMsg(e) });
      return [];
    }
  }

  @action.bound public async searchLocations(search: string): Promise<models.Location[]> {
    if (this.props.onlyUnconverted) return this.searchUnconvertedLocations(search);
    if (this.accountId) return this.searchAccountLocations(search);
    return this.searchAllLocations(search);
  }

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.disposers.map((disposer) => disposer());
  }

  render() {
    const { onChange, hideIcon, label, placeholder, value, autoFocus, dataCy } = this.props;
    return (
      <AutocompleteSearch
        autoFocus={autoFocus}
        fetch={this.searchLocations}
        onChange={onChange}
        label={label}
        hideIcon={hideIcon}
        value={value}
        getOptionLabel={this.getLocationOptionLabel}
        getOptionSelected={(option, value) => option.id === value.id}
        renderOption={this.renderLocationOption}
        placeholder={placeholder}
        dataCy={dataCy}
      />
    );
  }
}

export default LocationSearch;
