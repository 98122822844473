import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  observable,
  action,
  flow,
  computed,
  makeObservable,
  autorun,
  IReactionDisposer,
} from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';

import Api, { getErrorMsg } from 'api';
import { inject, WithUserStore, WithToastStore, WithUiStore } from 'stores';
import * as models from 'models';
import { Address, DrawerType } from 'types';

import UsersPanel from 'components/UsersPanel';
import AddressPanel from 'components/AddressPanel';
import DevicesPanel from 'components/DevicesPanel';

import LocationGeneralPanel from 'components/LocationGeneralPanel';

import styles from './styles';
import { Account } from 'models';
import DisplayNamePanel from 'components/DisplayNamePanel';
import theme from 'containers/App/theme';
import TippyMasonry from 'components/TippyMasonry/TippyMasonry';

/** Here we define what kind of props this component takes */
type LocationInfoProps = WithStyles<typeof styles> & // Adds the classes prop
  WithUserStore & // Adds the userStore prop
  WithUiStore &
  WithToastStore & {
    location?: models.Location;
    account?: Account;
    updateLocation: () => void;
  }; // Adds the toastStore prop

/**
 * Container for displaying single location related panels
 */
@inject('userStore', 'toastStore', 'uiStore')
@observer
class LocationInfo extends React.Component<LocationInfoProps> {
  public constructor(props: LocationInfoProps) {
    super(props);
    makeObservable(this);
    // Make the params from the URL match observable. We do that so that
    // we can reference the accountId from compted values. We do this in
    // the constructor so that it's available before the first render.
    // this.matchParams = this.props.match.params;
    autorun(() => {
      if (this.props.location) {
        this.location = this.props.location;
        // this.getLocation();
        // Fetch location devices
        this.getDevices();
        // Fetch the employees for this account
        this.getEmployees();
        this.getSettings();
        this.getPools();
        this.getShippingAddress();
      }
    });

    this.disposers.push(autorun(() => (this.account = this.props.account)));
  }

  /**
   * It's good practice to dispose of any autoruns that we set up during
   */
  private disposers: IReactionDisposer[] = [];

  @observable public location?: models.Location = this.props.location;

  /** Account for this location */
  @observable public account?: models.Account = this.props.account;

  /** The user's devices */
  @observable devices?: models.Device[];

  /** The employees for this account */
  @observable public employees?: models.LocationUser[];

  /** Is pools drawer open? */
  @observable public poolsOpen = false;

  @observable public poolsDrawerType?: DrawerType;

  /** Account for this location */
  @observable public onlinePaymentEnabled?: boolean;

  /** Pools based on locationId prop passed as match param */
  @observable public pools?: models.Pool[];

  @observable public pool?: models.Pool;
  /** Which industry is enabled in settings */
  @observable public industry?: models.Industry;

  @observable public shippingAddress?: models.ShippingAddress;

  @observable public updateLocation = this.props.updateLocation;

  @computed get locationId() {
    if (this.location) {
      return this.location.id;
    }
  }

  /** Is auth user an admin? */
  @computed public get isAdmin() {
    return this.props.userStore && this.props.userStore.isAdmin;
  }

  /**
   * Users who count as talent are those that are tip eligible
   * somewhere and are not managers anywhere. For owner and
   * managers we wish to also filter inactive users.
   */
  @computed private get talent(): models.User[] | undefined {
    if (!this.employees) {
      return undefined;
    }
    // Tip eligible on one or more locations
    const isTalent = (user: models.LocationUser) => user.isTalent;
    // Active on this location
    const isActive = (user: models.LocationUser) => user.status === 'active';
    // Not a manager on any location
    const isNotManager = (user: models.LocationUser) => !user.isManager;

    let talents = this.employees.filter((employee) => isTalent(employee) && isNotManager(employee));

    // If auth user is not admin (owner or manager) filter out inactive talents
    if (!this.isAdmin) talents = talents.filter((talent) => isActive(talent));

    return talents.map((locationUser: models.LocationUser) => locationUser.user);
  }

  /** The users on this account that are managers on at least one location */
  @computed private get managers(): models.User[] | undefined {
    if (!this.employees) {
      return undefined;
    }
    // Get all the employees that are managers
    const isManager = (user: models.LocationUser) => user.isManager;
    return this.employees
      .filter(isManager)
      .map((locationUser: models.LocationUser) => locationUser.user);
  }

  @action.bound private openPoolsDrawer(type: DrawerType, poolId?: number) {
    // Reset pool
    this.pool = undefined;
    if (poolId) {
      this.pool = this.pools!.find((p) => p.id === poolId);
    }
    this.poolsOpen = true;
    this.poolsDrawerType = type;
  }

  @action.bound private closePoolsModal() {
    this.poolsOpen = false;
    this.pool = undefined;
  }

  @action.bound public getLocation = flow(function* (this: LocationInfo) {
    const resp = yield Api.core.getLocation(this.locationId!);
    this.location = resp.data.data;
  });

  @action.bound public getAccount = flow(function* (this: LocationInfo) {
    if (!this.location) return;
    const resp = yield Api.core.getAccount(this.location.accountId);
    this.account = resp.data.data;
  });

  /** Fetches the user's devices */
  @action.bound public getDevices = flow(function* (this: LocationInfo) {
    const resp = yield Api.core.getLocationDevices(this.locationId!);
    this.devices = resp.data && resp.data.data;
  });

  /** Fetches location users (talents and mangers) */
  @action.bound public getEmployees = flow(function* (this: LocationInfo) {
    const resp = yield Api.core.getAllLocationUsers(this.locationId!);
    this.employees = resp.data && resp.data.data;
  });

  /** Change location name */
  @action.bound public changeLocationName = flow(function* (this: LocationInfo, newTitle: string) {
    const resp = yield Api.core.updateLocation(this.locationId!, { name: newTitle });
    this.location = resp.data.data;
  });

  @action.bound public updateAddress = flow(function* (this: LocationInfo, addr: Address) {
    try {
      yield Api.core.updateLocation(this.locationId!, addr);
      this.props.toastStore!.success('Address updated!');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public createPool = flow(function* (this: LocationInfo, pool: any) {
    try {
      this.closePoolsModal();
      const createdPool = {
        accountId: this.account && this.account.id,
        locationId: this.locationId,
        ...pool,
      };
      yield Api.core.createPool(createdPool);
      this.getPools();
      this.props.toastStore!.success('Pool created!');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @action.bound public updatePool = flow(function* (
    this: LocationInfo,
    poolId: number,
    pool: models.Pool,
  ) {
    try {
      this.closePoolsModal();
      yield Api.core.updatePool(poolId, pool);
      this.getPools();
      this.props.toastStore!.success('Pool Updated!');
    } catch (e: any) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  @computed get locationUid(): string {
    return this.location && this.location.uid ? this.location.uid : '';
  }

  @computed get loading(): boolean {
    return !this.location;
  }

  // /** Fetches location pools (owners and mangers) */
  @action.bound public getPools = flow(function* (this: LocationInfo) {
    const resp = yield Api.core.getPoolsList({
      accountId: this.location!.accountId,
      locationId: this.locationId,
    });
    this.pools = resp && resp.data.data;
  });

  @action.bound public getSettings = flow(function* (this: LocationInfo) {
    const resp = yield Api.core.getSettings('location', this.locationId!);
    const settings = resp && resp.data && resp.data.data;

    if (settings) {
      this.onlinePaymentEnabled = settings.onlinePaymentsEnabled;
      this.industry = settings.industry;
    }
  });

  @action.bound public getShippingAddress = flow(function* (this: LocationInfo) {
    const resp = yield Api.core.getShippingAddress(this.location!.id!);
    const shippingAddress = resp?.data?.data.filter((e: models.ShippingAddress) => e.default);
    this.shippingAddress = shippingAddress[0];
  });

  @action.bound public updateShippingAddress = flow(function* (this: LocationInfo, addr: Address) {
    try {
      yield Api.core.updateShippingAddress(this.location!.id!, this.shippingAddress!.id, addr);
      this.props.toastStore!.success('Address updated!');
    } catch (e: unknown) {
      this.props.toastStore!.error(getErrorMsg(e));
    }
  });

  render() {
    const { classes } = this.props;
    const showShippingAddress = this.shippingAddress && this.isAdmin;
    const mobilePageContent = this.props.uiStore?.singleColumnPageContent;
    const flexDirection = mobilePageContent ? 'column' : 'row';
    const styles = {
      flexGrow: 1,
    };

    return (
      <>
        <Grid container direction={'row'} spacing={3}>
          <TippyMasonry>
            {/* Display Name */}
            {this.location && this.locationId ? (
              <Box className={''}>
                <DisplayNamePanel
                  displayName={this.location.displayName || 'N/A'}
                  locationId={this.locationId}
                  updateLocation={this.updateLocation}
                />
              </Box>
            ) : null}

            <Box className={classes.secondaryItem}>
              <UsersPanel
                displayInviteManagersButton
                talent={this.talent}
                managers={this.managers}
                location={this.location}
                fullHeight
              />
            </Box>

            {showShippingAddress && (
              <LocationGeneralPanel
                industry={this.industry}
                title="General"
                code={this.locationUid}
                type="location"
                disabled={!this.onlinePaymentEnabled}
                account={this.account}
                location={this.location}
              />
            )}
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              flexDirection={flexDirection}
              style={{ gap: theme.spacing(3) }}>
              {!showShippingAddress && (
                <Box style={styles}>
                  <LocationGeneralPanel
                    industry={this.industry}
                    title="General"
                    code={this.locationUid}
                    type="location"
                    disabled={!this.onlinePaymentEnabled}
                    account={this.account}
                    location={this.location}
                    fullHeight
                  />
                </Box>
              )}

              <Box style={styles}>
                <AddressPanel
                  title="Address"
                  addressEditable={this.isAdmin}
                  onUpdateAddress={this.isAdmin ? this.updateAddress : undefined}>
                  {this.location}
                </AddressPanel>
              </Box>

              {showShippingAddress && (
                <Box style={styles}>
                  <AddressPanel
                    title="Shipping Address"
                    onUpdateAddress={this.isAdmin ? this.updateShippingAddress : undefined}>
                    {this.shippingAddress}
                  </AddressPanel>
                </Box>
              )}
            </Box>

            <Box className={!(this.shippingAddress && this.isAdmin) ? classes.gridItemSpan : ''}>
              <DevicesPanel devices={this.devices} />
            </Box>
          </TippyMasonry>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(LocationInfo);
