import { useEffect } from 'react';

const useIsBlurred = (onBlur: VoidFunction | null, onFocus: VoidFunction | null) => {
  useEffect(() => {
    const handleBlur = () => {
      if (!onBlur) return;
      onBlur();
    };

    const handleFocus = () => {
      if (!onFocus) return;
      onFocus();
    };

    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);

    return () => {
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return null;
};

export default useIsBlurred;
