// noinspection JSUnusedLocalSymbols

import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { PlaidLinkOptions, usePlaidLink } from 'react-plaid-link';
import PlaidAndroidRedirect from 'components/PlaidRedirect/PlaidAndroidRedirect';

const PlaidTokenRedirect = ({ token, onSuccess }: { token: any; onSuccess: any }) => {
  const config: PlaidLinkOptions = {
    // When re-initializing Link after OAuth redirection, the same
    // Link token from the first initialization must be used
    token: token!,
    onSuccess,
    // receivedRedirectUri: document.location.href, // required for OAuth
    // onExit
    // onEvent
  };

  const { open, ready, error } = usePlaidLink(config);

  // this opens link as soon as it's ready
  useEffect(() => {
    if (!ready || !token) {
      return;
    }
    open();
  }, [ready, open, token]);

  return null;
};

const PlaidRedirect: FunctionComponent = () => {
  // @ts-ignore
  const onSuccess = useCallback((public_token, metadata) => {
    // send public_token to server
  }, []);

  const [showAndroidRedirect, setShowAndroidRedirect] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined | null>(
    window.localStorage.getItem('plaidLinkToken'),
  );

  useEffect(() => {
    if (token) {
      setToken(token);
    } else {
      setShowAndroidRedirect(true);
    }
  }, []);

  // eslint-disable-next-line no-constant-condition
  if (showAndroidRedirect) {
    return <PlaidAndroidRedirect />;
  }

  if (token) {
    return <PlaidTokenRedirect token={token} onSuccess={onSuccess} />;
  }

  // don't render anything, just open Link
  return null;
};

export default PlaidRedirect;
