import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { SALES_TEAM_EMAIL, SALES_TEAM_PHONE } from 'utils/constants';
import theme from 'containers/App/theme';

const ContactInfo: React.FC = () => {
  return (
    <>
      <Box pt={3}>
        <Typography align="center" variant="body2">
          If you have any questions, please contact our Tipping Experts at {SALES_TEAM_PHONE} or{' '}
          {SALES_TEAM_EMAIL}
        </Typography>
      </Box>
      <Box pt={3} sx={contactInfoStyles}>
        <Typography align="center">
          Made by Direct Tips Operations LLC | 2755 E Oakland Park Blvd. Ste 300, Ft. Lauderdale, FL
          33306
        </Typography>
      </Box>
    </>
  );
};

export default ContactInfo;

const contactInfoStyles = {
  display: 'block',
  '& .MuiTypography-root': {
    fontSize: 12,
    paddingTop: 3,
    color: theme.palette.common.black,
  },
};
