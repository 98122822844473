/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  MenuItem,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { AutocompleteResult, GoogleApiHelper } from 'components/AddressPanel/GoogleApiHelper';
import AutocompleteSearch from 'components/AutocompleteSearch';
import Button from 'components/Button/Dialog/Button';
import OutlinedInput from 'components/Input/OutlinedInput';
import Overlay from 'components/Overlay';
import Title from 'components/Title/Dialog/Title';
import { Close, MapMarker, Pencil } from 'mdi-material-ui';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { isZip, states } from 'services';
import { inject, WithToastStore } from 'stores';
import type { Address } from 'types/address';
import validatorjs from 'validatorjs';
import styles from './styles';

import {
  ZIP_CODE_REGEX,
  ADDRESS_REGEX,
  NON_ZERO_NUMERIC_REGEX,
  CHECK_NUM_REGEX,
  CITY_REGEX,
} from 'utils/regexList';

type MobxForm = any;

interface FormHooks {
  onSuccess: (form: MobxForm) => void;
}
//
// TODO: Zip validation
//

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;

const plugins = {
  dvr: dvr({
    package: validatorjs,
    extend: ({ validator }: { validator: any; form: MobxForm }) => {
      validator.register('zip', isZip, 'Please input a valid zip code');
    },
  }),
};

interface AddressFieldProps extends WithToastStore, WithStyles<typeof styles> {
  onChange: (a: Address | null) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  enableCustomInput?: boolean;
  initialCustomAddress?: Address;
  label?: string;
  InputProps?: { disableUnderline?: boolean; className?: string; dataCy?: string };
  className?: string;
  dataCy?: string;
}

const addressTypes = [
  'route',
  'street_number',
  'locality',
  'sublocality',
  'administrative_area_level_1',
  'postal_code',
];

/**
 * A form field for searching addresses.
 */
@inject('toastStore')
@observer
class AddressField extends React.Component<AddressFieldProps> {
  constructor(props: AddressFieldProps) {
    super(props);
    makeObservable(this);
  }
  public ref?: React.RefObject<any>;
  /** Selected location set on onSelect autocomplete event */
  @observable public selectedPlace?: {
    subpremise: string;
    route: string;
    street_number: string;
    locality: string;
    sublocality: string;
    administrative_area_level_1: string;
    postal_code: string;
    lat?: number;
    long?: number;
  };
  /** The validation error, if any */
  @observable public validationError: string | null = null;

  /** The custom address, if present */
  @observable public customAddress: Address | null = this.props.initialCustomAddress || null;

  /** Whether we're currently fetching custom address details (lat and long) */
  @observable public fetchingCustomAddressDetails = false;

  /** Whether the custom address dialog is open */
  @observable public customDialogOpen = false;

  @computed public get validationMsg() {
    return this.validationError ? `Missing ${this.validationError}` : undefined;
  }
  /** Google's PlacesService constructor takes a mandatory html element as an argument */
  // See: https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService.constructor
  @observable public detailsELRef = React.createRef<HTMLDivElement>();

  public fields = [
    {
      name: 'address',
      label: 'Address line 1',
      rules: 'required',
      value: (this.customAddress && this.customAddress.address) || '',
    },
    {
      name: 'address2',
      label: 'Address line 2',
      value: (this.customAddress && this.customAddress.address2) || '',
    },
    {
      name: 'city',
      label: 'City',
      rules: 'required',
      value: (this.customAddress && this.customAddress.city) || '',
    },
    {
      name: 'zip',
      label: 'Zip',
      rules: 'required|zip',
      value: (this.customAddress && this.customAddress.zip) || '',
    },
    {
      name: 'state',
      label: 'State',
      rules: 'required',
      value: (this.customAddress && this.customAddress.state) || '',
    },
  ];

  private hooks: FormHooks = {
    onSuccess: () => this.submitCustomForm(),
  };

  public form: MobxForm = new MobxReactForm(
    { fields: this.fields },
    { plugins, hooks: this.hooks },
  );

  @action.bound public searchAddresses = flow(function* (this: AddressField, s: string) {
    if (!s) {
      return [];
    }
    try {
      return yield GoogleApiHelper.search(s);
    } catch (e: any) {
      if (e !== 'ZERO_RESULTS') {
        this.props.toastStore!.push({
          message: 'Whoops, something went wrong',
          type: 'error',
        });
      }
      return [];
    }
  });

  @action.bound public selectResult = flow(function* (
    this: AddressField,
    result: AutocompleteResult | null,
  ) {
    this.validationError = null;
    this.selectedPlace = undefined;
    if (result === null) {
      this.props.onChange && this.props.onChange(null);
      return;
    }
    this.customAddress = null;
    this.setFormToMatchCustomAddress();
    const node = this.detailsELRef.current;
    try {
      const r = yield GoogleApiHelper.getPlaceDetails(result.id, node as HTMLDivElement);
      this.selectedPlace = this.parseData(r);
      this.validateAddress(this.selectedPlace);
      if (!this.validationError && this.selectedPlace) {
        const address = this.selectedPlace;
        this.props.onChange({
          address: `${address.street_number} ${address.route}`,
          address2: address.subpremise,
          city: address.locality ? address.locality : address.sublocality,
          state: address.administrative_area_level_1,
          zip: address.postal_code,
          lat: address.lat,
          long: address.long,
        });
      }
    } catch (e: any) {
      // List of possible errors:
      // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesServiceStatus
      if (e !== 'ZERO_RESULTS') {
        this.props.toastStore!.push({
          message: 'Whoops, something went wrong',
          type: 'error',
        });
      }
    }
  });

  /** Map over and trim placesService.getDetails() API response */
  @action.bound public parseData(placeDetailsRaw: any) {
    const addrComponents = placeDetailsRaw.address_components.reduce(
      (structured: any, component: any) => {
        let typeMatch = component.types.filter((type: string) => addressTypes.includes(type));
        // 'administrative_area_level_1' is state part of the address and we want short name for it:
        let isTypeState = typeMatch[0] && typeMatch[0] === 'administrative_area_level_1';
        if (component.types[0] === 'subpremise') {
          typeMatch = [component.types[0]];
        }
        return typeMatch.length
          ? { ...structured, [typeMatch]: isTypeState ? component.short_name : component.long_name }
          : { ...structured };
      },
      {},
    );
    const location = placeDetailsRaw.geometry && placeDetailsRaw.geometry.location;
    const lat = location && location.lat();
    const long = location && location.lng();
    return { ...addrComponents, lat, long };
  }

  /** Check if API response for selected place is missing any required data */
  @action.bound public validateAddress(formattedAddress: any) {
    if (!formattedAddress['sublocality'] && !formattedAddress['locality']) {
      this.validationError = 'city';
    }
    if (!formattedAddress['route']) {
      this.validationError = 'street name';
    }
    if (!formattedAddress['street_number']) {
      this.validationError = 'street number';
    }
    if (!formattedAddress['administrative_area_level_1']) {
      this.validationError = 'state';
    }
    if (!formattedAddress['postal_code']) {
      this.validationError = 'zip';
    }
  }

  @computed public get customAddressString(): string {
    if (!this.customAddress) return '';
    const { address, address2, city, state, zip } = this.customAddress;
    return [address, address2, city, zip, state].filter((v) => !!v).join(', ');
  }

  /**
   * Returns the lat and long for the first place matching the search string
   */
  public getLatLong = async (search: string): Promise<Pick<Address, 'lat' | 'long'>> => {
    try {
      const node = this.detailsELRef.current;
      const results = await GoogleApiHelper.search(search);
      const pd = await GoogleApiHelper.getPlaceDetails(results[0].id, node as HTMLDivElement);
      return {
        lat: pd.geometry.location.lat(),
        long: pd.geometry.location.lng(),
      };
    } catch (e: any) {
      return { lat: undefined, long: undefined };
    }
  };

  @action.bound public clearCustomAddress() {
    this.customAddress = null;
    this.form.clear();
    this.ref && this.ref.current.focus();
  }

  @action.bound public showCustomDialog() {
    this.customDialogOpen = true;
  }

  @action.bound public closeCustomDialog() {
    this.customDialogOpen = false;
  }

  @action.bound public cancelCustomDialog() {
    this.closeCustomDialog();
    this.setFormToMatchCustomAddress();
  }

  @action.bound public setFormToMatchCustomAddress() {
    if (this.customAddress) {
      const { address, address2, city, state, zip } = this.customAddress;
      this.form.update({
        address,
        address2: address2 || '',
        city,
        state,
        zip,
      });
    } else {
      this.form.clear();
    }
  }

  @action.bound public submitCustomForm() {
    this.customAddress = {
      address: this.form.$('address').value,
      address2: this.form.$('address2').value,
      city: this.form.$('city').value,
      state: this.form.$('state').value,
      zip: this.form.$('zip').value,
    };
    this.props.onChange(this.customAddress);
    this.closeCustomDialog();
  }

  @computed public get displayManualInputLink() {
    return this.props.enableCustomInput && !this.customAddress;
  }
  renderCustomAddress() {
    return (
      <OutlinedInput
        dataCy={this.props.dataCy}
        disabled
        label={'Address'}
        value={this.customAddressString}
        InputProps={{
          startAdornment: <MapMarker />,
          endAdornment: (
            <React.Fragment>
              {this.customAddress && (
                <IconButton size="small" onClick={this.showCustomDialog}>
                  <Pencil color="action" fontSize="small" />
                </IconButton>
              )}
              <IconButton size="small" onClick={this.clearCustomAddress}>
                <Close color="action" fontSize="small" />
              </IconButton>
            </React.Fragment>
          ),
        }}
        fullWidth
        style={{ width: '100%' }}
      />
    );
  }
  renderCustomDialog() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.customDialogOpen}
        onClose={this.cancelCustomDialog}
        className={clsx(classes.dialog)}
        fullWidth>
        <Overlay display={this.fetchingCustomAddressDetails}>
          <CircularProgress />
        </Overlay>
        <DialogTitle disableTypography>
          <Title>Edit Address</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <OutlinedInput
                {...this.form.$('address').bind()}
                error={Boolean(this.form.$('address').error)}
                helperText={this.form.$('address').error}
                fullWidth
                autoFocus
                required
                dataCy="address-input"
                onChange={(event) => {
                  this.form.$('address').set(event.target.value.replace(ADDRESS_REGEX, ''));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput
                {...this.form.$('address2').bind()}
                error={Boolean(this.form.$('address2').error)}
                helperText={this.form.$('address2').error}
                fullWidth
                dataCy="address2-input"
                onChange={(event) => {
                  this.form.$('address2').set(event.target.value.replace(ADDRESS_REGEX, ''));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput
                {...this.form.$('city').bind()}
                error={Boolean(this.form.$('city').error)}
                helperText={this.form.$('city').error}
                fullWidth
                required
                dataCy="city-input"
                onChange={(event) => {
                  this.form.$('city').set(event.target.value.replace(CITY_REGEX, ''));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput
                {...this.form.$('zip').bind()}
                error={Boolean(this.form.$('zip').error)}
                helperText={this.form.$('zip').error}
                fullWidth
                required
                dataCy="zip-input"
                onChange={(event) => {
                  if (
                    ZIP_CODE_REGEX.test(event.target.value.replace(CHECK_NUM_REGEX, '')) &&
                    event.target.value.length <= 5
                  ) {
                    this.form.$('zip').set(event.target.value.replace(CHECK_NUM_REGEX, ''));
                  }
                }}
                onBlur={(event) => {
                  if (NON_ZERO_NUMERIC_REGEX.test(event.target.value)) {
                    this.form.$('zip').set(event.target.value);
                  } else {
                    this.form.$('zip').set('');
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <OutlinedInput
                {...this.form.$('state').bind()}
                select
                error={Boolean(this.form.$('state').error)}
                helperText={this.form.$('state').error}
                required
                fullWidth
                dataCy="state-input">
                {Object.entries(states).map(([key, label]) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </OutlinedInput>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={this.cancelCustomDialog}
            data-cy="cancel-custom-address">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              this.form && this.form.submit();
            }}
            data-cy="submit-custom-address">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  render() {
    const {
      disabled,
      autoFocus,
      onBlur,
      onFocus,
      error,
      helperText,
      classes,
      label,
      className,
      InputProps,
    } = this.props;

    const manualInputLink = this.displayManualInputLink && (
      <Link onClick={this.showCustomDialog} className={classes.customInputLink}>
        Search not working? Try manual input
      </Link>
    );
    const message = (
      <Box pr={2} component={'span'}>
        {this.validationMsg || helperText}
      </Box>
    );
    const helper = (
      <Box>
        {message}
        {manualInputLink}
      </Box>
    );

    return (
      <>
        <div ref={this.detailsELRef}></div>
        {this.renderCustomDialog()}
        {!this.customAddress ? (
          <AutocompleteSearch
            dataCy={this.props.dataCy}
            className={className}
            InputProps={InputProps}
            autoFocus={autoFocus}
            label={label}
            disabled={disabled}
            fetch={this.searchAddresses}
            onBlur={onBlur}
            ref={this.ref}
            onFocus={onFocus}
            icon={MapMarker}
            error={Boolean(this.validationMsg || error)}
            helperText={helper}
            getOptionLabel={(a) => (a ? a.value : '')}
            onChange={this.selectResult}
          />
        ) : (
          this.renderCustomAddress()
        )}
      </>
    );
  }
}

export default withStyles(styles)(AddressField);
