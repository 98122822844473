import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  observable,
  action,
  computed,
  flow,
  reaction,
  toJS,
  IReactionDisposer,
  makeObservable,
} from 'mobx';
import { observer } from 'mobx-react';

import Api from 'api';
import { inject, WithModalStore, WithToastStore, WithUserStore } from 'stores';
import { paths } from 'routes';
import { Location, LocationUser } from 'models';

import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Typography, Link, Box, Tooltip } from '@material-ui/core';
import { OpenInNew } from 'mdi-material-ui';

import DP from 'components/DashPanel';

import styles from './styles';
import ChipStatusTag from 'components/ChipStatusTag';
import { ChipStatusColors } from 'components/ChipStatusTag';
import { ExpandMore } from '@material-ui/icons';

enum UserStatus {
  active,
  inactive,
}

interface UserLocationPanelProps
  extends WithStyles<typeof styles>,
    WithModalStore,
    WithToastStore,
    WithUserStore {
  children?: LocationUser | null;
  editable?: boolean;
}

/**
 * Displays location user panel, where user can change settings for specific location user.
 * Configurable values are 'isManager', 'isVisible', `isTalent` and 'status'
 */
@inject('modalStore', 'toastStore', 'userStore')
@observer
class UserLocationPanel extends React.Component<UserLocationPanelProps> {
  static defaultProps = {
    editable: true,
  };
  constructor(props: UserLocationPanelProps) {
    super(props);
    makeObservable(this);
    // Set up an autorun so that when the user in the props changes, we copy it
    // to this component's state. This achieves the same thing as implementing a
    // componentDidUpdate and seeing if the user prop has changed.
    // We push the autorun onto a disposers array so that we can dispose of all
    // of them before the component unmounts.
    this.reaction = reaction(
      () => this.props.children,
      (newLocationUser) => {
        this.locationUser = toJS(newLocationUser);
      },
    );
  }

  private reaction: IReactionDisposer;

  /** The user object is copied to this property */
  @observable private locationUser? = toJS(this.props.children);

  @computed private get isManager() {
    return this.locationUser && this.locationUser.isManager;
  }

  /** Loading observables */
  @observable private updatingIsManager = false;
  @observable private updatingIsVisible = false;
  @observable private updatingIsTalent = false;
  @observable private updatingStatus = false;
  @computed private get updating() {
    return this.updatingIsManager || this.updatingIsVisible || this.updatingStatus;
  }

  /** Generate list of user status values from UserStatus enum */
  private userStatusList = Object.values(UserStatus).filter(
    (value) => typeof value === 'string',
  ) as string[];

  /** On change handler for isManager field toggle event. Also handles confirmation popup modal */
  @action.bound private handleManagerRoleToggle = flow(function* (this: UserLocationPanel) {
    const updatedUserData = {
      userId: this.locationUser!.userId,
      isManager: !this.locationUser!.isManager,
    };

    const modalTitle = `Set location manager`;
    const modalMessage = this.locationUser!.isManager
      ? `Are you sure you want to remove this user as manager?`
      : `Are you sure you want to set this user to manager?`;
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage);

    if (confirmed) {
      const previous = this.locationUser!.isManager;
      this.locationUser!.isManager = !this.locationUser!.isManager;
      this.updatingIsManager = true;
      try {
        yield Api.core.updateLocationUser(this.locationUser!.locationId, updatedUserData);
        const toastMessage = this.locationUser!.isManager
          ? `User was set as manager`
          : `User was removed as manager`;
        this.props.toastStore!.push({ type: 'success', message: toastMessage });
      } catch (e: any) {
        const errorMsg =
          e.response && e.response.data && e.response.data.error && e.response.data.error.message;
        this.props.toastStore!.push({ type: 'error', message: errorMsg });
        this.locationUser!.isManager = previous;
      } finally {
        this.updatingIsManager = false;
      }
    }
  });

  /**
   * On change handler for isVisible field toggle event.
   * Also handles confirmation popup modal
   */
  @action.bound private handleVisibilityStatusToggle = flow(function* (this: UserLocationPanel) {
    const updatedUserData = {
      userId: this.locationUser!.userId,
      isVisible: !this.locationUser!.isVisible,
    };

    const modalTitle = `Setting user visibility`;
    const modalMessage = this.locationUser!.isVisible
      ? `Are you sure you want to hide this user?`
      : `Are you sure you want to show this user?`;
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage);

    if (confirmed) {
      const previous = this.locationUser!.isVisible;
      this.locationUser!.isVisible = !this.locationUser!.isVisible;
      this.updatingIsVisible = true;
      try {
        yield Api.core.updateLocationUser(this.locationUser!.locationId, updatedUserData);
        const toastMessage = this.locationUser!.isVisible
          ? `User was set to visible`
          : `User was hidden`;
        this.props.toastStore!.push({ type: 'success', message: toastMessage });
      } catch (e: any) {
        this.locationUser!.isVisible = previous;
        const errorMsg =
          e.response && e.response.data && e.response.data.error && e.response.data.error.message;
        this.props.toastStore!.push({ type: 'error', message: errorMsg });
      } finally {
        this.updatingIsVisible = false;
      }
    }
  });

  /**
   * On change handler for isTalent field toggle event.
   * Also handles confirmation popup modal
   */
  @action.bound private handleTalentStatusToggle = flow(function* (this: UserLocationPanel) {
    const updatedUserData = {
      userId: this.locationUser!.userId,
      isTalent: !this.locationUser!.isTalent,
    };

    const modalTitle = `Setting user employee status`;
    const modalMessage = this.locationUser!.isTalent
      ? `Are you sure you want unset user as employee?`
      : `Are you sure you want set user as employee?`;
    const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage);

    if (confirmed) {
      const previous = this.locationUser!.isTalent;
      this.locationUser!.isTalent = !this.locationUser!.isTalent;
      this.updatingIsTalent = true;
      try {
        yield Api.core.updateLocationUser(this.locationUser!.locationId, updatedUserData);
        const toastMessage = this.locationUser!.isTalent
          ? `User was set as employee`
          : `User was unset as employee`;
        this.props.toastStore!.push({ message: toastMessage });
      } catch (e: any) {
        this.locationUser!.isTalent = previous;
        const errorMsg =
          e.response && e.response.data && e.response.data.error && e.response.data.error.message;
        this.props.toastStore!.push({ type: 'error', message: errorMsg });
      } finally {
        this.updatingIsTalent = false;
      }
    }
  });

  /**
   * On change handler for status field toggle event.
   * Also handles confirmation popup modal
   */
  @action.bound private handleStatusChange = flow(function* (
    this: UserLocationPanel,
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) {
    const locationUserStatus = event.target.value as string;
    const updatedUserData = {
      userId: this.locationUser!.userId,
      status: locationUserStatus,
    };

    const modalTitle = `Setting user status`;
    const modalMessage = `Are you sure you want to change user status?`;

    // Only prompt for action confirmation modal if value has actually changed
    if (this.locationUser!.status !== locationUserStatus) {
      const confirmed = yield this.props.modalStore!.confirm(modalTitle, modalMessage);
      if (confirmed) {
        this.locationUser!.status = locationUserStatus;
        this.updatingStatus = true;
        yield Api.core.updateLocationUser(this.locationUser!.locationId, updatedUserData);
        this.updatingStatus = false;
        const toastMessage = `User status set to ${locationUserStatus.toUpperCase()}`;
        this.props.toastStore!.push({ type: 'success', message: toastMessage });
      }
    }
  });

  /** Before unmounting the component, dispose of all autoruns created */
  componentWillUnmount() {
    this.reaction();
  }

  render() {
    const classes = this.props.classes;
    const isAdmin = this.props.userStore!.authUser.isAdmin;
    const amIManager = this.props.userStore!.scope.kind === 'manager';
    const amIOwner = this.props.userStore!.scope.kind === 'owner';
    const displayValue =
      this.locationUser?.status === 'pending_approval'
        ? 'pending approval'
        : this.locationUser?.status;

    if (!this.locationUser) {
      return (
        <DP>
          <DP.Header>
            <DP.Loading items={1} />
          </DP.Header>
          <DP.Body>
            <DP.Loading items={4} />
          </DP.Body>
        </DP>
      );
    }

    const { locationName, name } = this.locationUser.location || ({} as Location);

    return (
      <DP>
        <DP.Header>
          <DP.Title panel>{isAdmin ? locationName : name}</DP.Title>
          <DP.Actions>
            {this.updating && <DP.LoadSpinner />}
            {(amIOwner || isAdmin) && (
              <Link
                className={classes.linkIcon}
                component={RouterLink}
                to={paths.locationDetails(this.locationUser.location!.id)}>
                <Tooltip title="LOCATION">
                  <OpenInNew color="primary" fontSize="small" />
                </Tooltip>
              </Link>
            )}
          </DP.Actions>
        </DP.Header>
        <DP.Body>
          <Box display="flex">
            <Box width={'60%'}>
              <DP.Row>
                <DP.Row>
                  <DP.Value>{this.locationUser.location!.account!.name}</DP.Value>
                  <DP.Label>Account</DP.Label>
                </DP.Row>
                <Box>
                  <DP.Value>
                    {this.props.editable ? (
                      <DP.Select
                        icon={ExpandMore}
                        noBorder
                        items={this.userStatusList}
                        value={this.locationUser.status}
                        onChange={this.handleStatusChange}
                        renderValue={() => <Box component={'span'}>{displayValue}</Box>}
                      />
                    ) : (
                      <Typography className={classes.statusValue}>
                        {this.locationUser.status}
                      </Typography> // get this from boolean this.props.account.isActive
                    )}
                  </DP.Value>
                  <DP.Label>Status</DP.Label>
                </Box>
              </DP.Row>
            </Box>

            <Box>
              <DP.Row>
                <DP.Row>
                  {this.props.editable ? (
                    <DP.Switch
                      checked={this.locationUser.isVisible}
                      onChange={this.handleVisibilityStatusToggle}
                    />
                  ) : (
                    <ChipStatusTag label="YES" color={ChipStatusColors.GREEN} />
                  )}
                  <DP.Label>Visible</DP.Label>
                </DP.Row>

                {this.props.editable && !amIManager && (
                  <Box>
                    <DP.Switch
                      checked={this.locationUser.isManager}
                      onChange={this.handleManagerRoleToggle}
                    />
                    <DP.Label mt={0.625}>Manager</DP.Label>
                  </Box>
                )}
              </DP.Row>
            </Box>
          </Box>
        </DP.Body>
      </DP>
    );
  }
}

export default withStyles(styles)(UserLocationPanel);
