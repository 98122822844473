import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Link, Box } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { RouteComponentProps, Link as RouterLink } from 'react-router-dom';

import { WithUserStore, inject, OwnerScope, WithToastStore } from 'stores';

import { Location } from 'models';
import { paths } from 'routes';
import Api, * as api from 'api';

import styles from './styles';

import ChipStatusTag from '../ChipStatusTag';
import DataGridInfiniteScroll from 'components/DataGridInfiniteScroll';
import * as DateRangeExternalPicker from 'components/DateRangeExternalPicker';

import { Filter, FilterItem } from '../FilterBar/FilterBar';
import FilterBar from '../FilterBar';
import { states } from 'services';

interface LocationsTableProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    WithToastStore,
    RouteComponentProps {
  locations?: Location[];
}

/** Annotates location with extra data */
// TODO: add data (counts) when added to api data
function annotateLocation(location: Location) {
  return {
    ...location,
    accountName: location.account ? location.account!.name : undefined,
    // This is a temporary fix because datagrid doesn't display zeros
    deviceCount: location.deviceCount ? (location.deviceCount || 0).toString() : undefined,
  };
}

/**
 * Displays a scrollable list of locations for quick viewing. Includes
 * a filter. Only admins and owners can see this location. If scope is admin
 * send datagrid a fetch function with enabled pagination, filtering and extra request data.
 * If scope is owner prefetch account based location and send the list to this component.
 */
@inject('userStore', 'toastStore')
@observer
class LocationsTable extends React.Component<LocationsTableProps> {
  constructor(props: LocationsTableProps) {
    super(props);
    makeObservable(this);
  }

  /** Active filters as returned by FilterBar */
  @observable public queryFilters: Record<string, unknown> = {};

  /** The selected date range */
  @observable public dateRange: DateRangeExternalPicker.DateRange =
    DateRangeExternalPicker.getDateRange();

  @observable public isOwner: boolean = this.props.userStore!.scope.kind === 'owner';
  @observable public isGlobalOwner: boolean = this.props.userStore!.scope.kind === 'global_owner';

  @computed public get stateFilterItems(): FilterItem[] {
    return Object.entries(states).map((e) => ({ value: e[0], label: e[1] }));
  }

  @observable public filters: Filter[] = [];

  @action.bound async fetchFilterItems(): Promise<void> {
    this.filters =
      this.isOwner || this.isGlobalOwner ? this.ownerFiltersData : await this.adminFiltersData();
  }

  @action.bound async fetchDataLocations(rmd: api.RequestMetaData) {
    const res: any = await Api.core.getAllLocations({
      ...rmd,
      filters: {
        ...this.queryFilters,
      },
    });
    return {
      rows: res.data.data.map(annotateLocation),
      sortable: res.data.sortable,
      totalElements: res.data.count,
    };
  }

  /** Fetches all locations by Account */
  @action.bound async fetchDataLocationsByAccount(rmd: api.RequestMetaData) {
    const scope = this.props.userStore!.scope as OwnerScope;
    const id = scope.accountId;
    const res: any = await Api.core.getAllLocationsByAccount(id, {
      ...rmd,
      filters: {
        ...this.queryFilters,
      },
    });
    return {
      rows: res.data.data.map(annotateLocation),
      sortable: res.data.sortable,
      totalElements: res.data.count,
    };
  }

  @action.bound async fetchGloballyOwnedLocations(rmd: api.RequestMetaData) {
    const res: any = await Api.core.getGloballyOwnedLocations({
      ...rmd,
      filters: {
        ...this.queryFilters,
      },
    });
    return {
      rows: res.data.data.map(annotateLocation),
      sortable: res.data.sortable,
      totalElements: res.data.count,
    };
  }

  @computed get isAdmin() {
    return this.props.userStore!.isAdmin;
  }

  getFetch() {
    if (this.isOwner) {
      return this.fetchDataLocationsByAccount;
    }
    if (this.isGlobalOwner) {
      return this.fetchGloballyOwnedLocations;
    }
    return this.fetchDataLocations;
  }

  badgeConfig = {
    ACTIVE: '#4EAE8D',
    INACTIVE: '#E01E5A',
  };

  /** List of available filters for FilterBar component */
  @computed get ownerFiltersData(): Filter[] {
    return [
      { display: 'Location Name', id: 'name', label: 'Contains', type: 'text' },
      { display: 'Display Name', id: 'displayName', label: 'Contains', type: 'text' },
      {
        display: 'State',
        id: 'state',
        label: 'Starts with',
        type: 'autocomplete',
        items: this.stateFilterItems,
      },
      {
        display: 'Status',
        id: 'isActive',
        label: 'One of',
        type: 'select',
        items: [
          { label: 'ACTIVE', value: 'true' },
          { label: 'INACTIVE', value: 'false' },
        ],
      },
    ];
  }

  @action.bound async getProductsFilterItems(): Promise<FilterItem[]> {
    try {
      const res = await Api.billing.getLocationProducts();
      return (res.data?.data || []).map((product: { id: string; name: string }) => ({
        value: String(product.id),
        label: product.name,
      }));
    } catch (e) {
      this.props.toastStore!.error('Error while retrieving filterable products.');
      return [];
    }
  }

  @action.bound async adminFiltersData(): Promise<Filter[]> {
    const filters: Filter[] = [
      { display: 'Location Name', id: 'name', label: 'Contains', type: 'text' },
      { display: 'Display Name', id: 'displayName', label: 'Contains', type: 'text' },
      { display: 'Account', id: 'accountName', label: 'Contains', type: 'text' },
      {
        display: 'State',
        id: 'state',
        label: 'Starts with',
        type: 'autocomplete',
        items: this.stateFilterItems,
      },
      {
        display: 'Status',
        id: 'isActive',
        label: 'One of',
        type: 'select',
        items: [
          { label: 'ACTIVE', value: 'true' },
          { label: 'INACTIVE', value: 'false' },
        ],
      },
    ];

    const items: FilterItem[] = await this.getProductsFilterItems();
    if (items.length) {
      filters.push({
        display: 'Product',
        id: 'productIds',
        label: 'Equals',
        type: 'autocomplete',
        items,
      });
    }

    return filters;
  }

  componentDidMount(): void {
    this.fetchFilterItems();
  }

  renderAccountNameCell = (params: any) => (
    <Link component={RouterLink} to={paths.locationDetails(params.id)}>
      {params.value}
    </Link>
  );

  renderCellStatus({ value }: any) {
    return <ChipStatusTag status={value} />;
  }

  dataGridColumns =
    this.isOwner || this.isGlobalOwner
      ? [
          {
            headerName: 'Location Name',
            field: 'name',
            minWidth: 250,
            flex: 1,
            renderCell: this.renderAccountNameCell,
          },
          {
            headerName: 'Display Name',
            field: 'displayName',
            minWidth: 200,
            flex: 1,
          },
          { headerName: 'State', field: 'state', minWidth: 200, flex: 1 },
          { headerName: 'City', field: 'city', minWidth: 200, flex: 1 },
          {
            headerName: 'Code',
            field: 'code',
            minWidth: 200,
            flex: 1,
          },
          {
            headerName: 'Status',
            field: 'isActive',
            minWidth: 200,
            flex: 1,
            renderCell: this.renderCellStatus,
          },
        ]
      : [
          {
            headerName: 'Name',
            field: 'locationName',
            minWidth: 200,
            flex: 1,
            renderCell: this.renderAccountNameCell,
          },
          { headerName: 'Account', field: 'accountName', minWidth: 150, flex: 1 },
          { headerName: 'State', field: 'state', minWidth: 100, flex: 1 },
          { headerName: 'Code', field: 'code', minWidth: 130, flex: 1 },
          { headerName: 'Users', field: 'userCount', minWidth: 100, flex: 1 },
          { headerName: 'Devices', field: 'deviceCount', minWidth: 130, flex: 1 },
          {
            headerName: 'Status',
            field: 'isActive',
            minWidth: 150,
            renderCell: this.renderCellStatus,
          },
          { headerName: 'Products', field: 'activeLicenses', minWidth: 200, flex: 1 },
        ];

  render() {
    const { classes } = this.props;
    return (
      <div style={{ maxWidth: '100%' }}>
        <Box className={classes.root}>
          <FilterBar
            filters={this.filters}
            onChange={(filters: Record<string, unknown>) => {
              this.queryFilters = filters;
            }}
            locationMap={this.isAdmin}
          />
          <DataGridInfiniteScroll
            columns={this.dataGridColumns}
            fetch={this.getFetch()}
            refetchKey={this.queryFilters}
            disableColumnMenu
            pathname={this.props.location.pathname}
          />
        </Box>
      </div>
    );
  }
}

export default withStyles(styles)(LocationsTable);
