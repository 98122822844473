import React, { useEffect, useState } from 'react';
import OpenAppPage from 'components/WithAndroidRedirect/OpenAppPage/OpenAppPage';
import LoadingPage from 'routes/LoadingPage';
import { generateDeepLink } from 'utils/helper';
import { isOsAndroid } from 'utils/operatingSystem';

const PlaidAndroidRedirect = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [appRedirect, setAppRedirect] = useState({
    redirect: false,
    deepLink: '',
    error: false,
  });

  useEffect(() => {
    redirectToApp();
  }, []);

  const redirectToApp = () => {
    const isAndroid = isOsAndroid();
    const url = window.location.href;
    const newUrl = generateDeepLink(url, 'tippy:/');
    const error = !isAndroid ? true : false;
    setAppRedirect({
      redirect: true,
      deepLink: newUrl,
      error,
    });

    setIsLoading(false);
  };

  if (appRedirect.redirect) {
    return <OpenAppPage {...appRedirect} />;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return null;
};

export default PlaidAndroidRedirect;
