export const SYNCHRONIZE_NOTIFICATION_STORE_INTERVAL = 1 * 3600 * 1000;

export const REGEX_NON_NUMBER_VALUES = /\D/g;

export const MINIMAL_AGE_REQUIRED = 14;

export const LINK_APP_GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.app.tippy&hl=en_US';

export const LINK_APP_APPSTORE =
  'https://apps.apple.com/us/app/tippy-salon-and-spa-tipping/id1424269737';

export const SALES_TEAM_EMAIL = 'hello@meettippy.com';

export const SALES_TEAM_PHONE = '+1 (855) 831-2323';

export const SALES_SUP_EMAIL = 'support@meettippy.com';

export const ANDROID_TIPPY_MOBILE_INTENT = 'tippy://home';
