import React from 'react';
import { observer } from 'mobx-react';
import { Typography, Box, Grid } from '@material-ui/core';
import googlePlay from './googlePlay.png';
import appStore from './appStore.png';
import success from './success.svg';

import { useStyles } from './styles';
import Button from 'components/Button/Button';

interface NextStepsProps {
  confirmationNumber: string;
  accountCode: string;
  toDashboard: () => void;
}

/** Displays the next steps screen that's displayed after paying for an order */
const NextSteps: React.FC<NextStepsProps> = observer(
  ({ confirmationNumber, accountCode, toDashboard }) => {
    const classes = useStyles();
    return (
      <Grid container spacing={0} className={classes.nextStepsRoot}>
        <Grid item md={6} sm={12} className={classes.nextStepsLeft}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" component="h1">
              Thank You!
            </Typography>
            <Box mt={3}>
              <Typography color="textSecondary" align="center">
                Your order is confirmed!
              </Typography>
            </Box>
            <Box mt={1}>
              <img src={success} alt="success!" className={classes.successImg} />
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography className={classes.bold}>
              Confirmation number: <span className={classes.primary}>{confirmationNumber}</span>
            </Typography>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} className={classes.nextStepsRight}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h4" component="h1">
              Next Steps
            </Typography>
            <Box pb={3}></Box>
            <Typography className={classes.stepsText} color="textSecondary" align="center">
              -Share your company code. <br />
              -Have staff download the Tippy app and set up their accounts. <br />
              -Approve staff in your dashboard.
            </Typography>
            <Box pb={3}></Box>
            <Typography color="textSecondary" align="center">
              Company code:
            </Typography>
            <Box pb={3}></Box>
            <Typography className={classes.accountCode} color="primary">
              {accountCode}
            </Typography>
          </Box>
          <Box className={classes.appStoreSection}>
            <a
              href="https://apps.apple.com/us/app/tippy-salon-and-spa-tipping/id1424269737"
              rel="noreferrer noopener">
              <img
                src={appStore}
                alt="Download on the App Store"
                className={classes.downloadSticker}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.app.tippy&hl=en_US"
              rel="noreferrer noopener">
              <img
                src={googlePlay}
                alt="Download on Google Play"
                className={classes.downloadSticker}
              />
            </a>
          </Box>

          <Button
            className={classes.Button}
            color="primary"
            variant="contained"
            onClick={toDashboard}
            fullWidth>
            Go to dashboard
          </Button>
        </Grid>
      </Grid>
    );
  },
);

export default NextSteps;
